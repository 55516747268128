import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import FacebookComments from "../FacebookComments/FacebookComments";

import "./ReviewGuideContent.css";

const Heading = ({ id, text }) => {
  return (
    <h2 id={id}>
      <span>{text}</span>
    </h2>
  );
};

const TextContent = ({ id, content }) => {
  return (
    <div>
      <Heading id={`header-${id}`} text={content.heading} />
      {content.items.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item.type === "subheading" && (
              <h3 className="subheading">{item.value}</h3>
            )}
            {item.type === "paragraph" && (
              <div
                className="paragraph"
                dangerouslySetInnerHTML={{ __html: item.value }}
              />
            )}
            {item.type === "image" && (
              <img src={item.value.url} alt={item.value.altText || ""} />
            )}
            {item.type === "step" && (
              <div className="step">
                <div>
                  <span
                    style={{ color: "rgb(53, 120, 160)", fontWeight: "bold" }}
                  >
                    Step{" "}
                    {item.value.step.trim().startsWith("Step")
                      ? item.value.step.trim().replace(/^Step\s+/i, "")
                      : item.value.step}
                    :
                  </span>{" "}
                  {item.value.text}
                </div>
              </div>
            )}
            {item.type === "paragraphImage" && (
              <div className="paragraph-image-inline">
                <div
                  className="paragraph"
                  dangerouslySetInnerHTML={{ __html: item.value.paragraph }}
                />
                <div>
                  <img
                    src={item.value.image.url}
                    alt={item.value.image.altText || ""}
                  />
                </div>
              </div>
            )}
            {item.type === "imageParagraph" && (
              <div className="paragraph-image-inline">
                <div>
                  <img
                    src={item.value.image.url}
                    alt={item.value.image.altText || ""}
                  />
                </div>
                <div
                  className="paragraph"
                  dangerouslySetInnerHTML={{ __html: item.value.paragraph }}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const FaqsContent = ({ content }) => {
  const [expandedItems, setExpandedItems] = useState([]);

  const toggleItem = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(
        expandedItems.filter((itemIndex) => itemIndex !== index)
      );
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };

  return (
    <div className="faq">
      <Heading text={content.heading} id={content.id} />
      {content.description && (
        <div
          className="paragraph"
          dangerouslySetInnerHTML={{ __html: content.description }}
        />
      )}
      <div>
        {content.faqs.map((faq, index) => (
          <div key={index}>
            <h3 className="faq-question" onClick={() => toggleItem(index)}>
              {faq.subheading}{" "}
              {expandedItems.includes(index) ? (
                <FontAwesomeIcon icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} />
              )}
            </h3>
            {expandedItems.includes(index) && (
              <div
                className="faq-answer"
                dangerouslySetInnerHTML={{ __html: faq.paragraph }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const ConclusionContent = ({ content, contentType }) => {
  return (
    <div className="conclusion">
      <div>
        <Heading text={content.heading} id={content.id} />
        {content.description && (
          <div
            className="paragraph"
            dangerouslySetInnerHTML={{ __html: content.description }}
          />
        )}
        {contentType === "review" && (
          <div className="conclusion-rating">Rating {content.rating}/5</div>
        )}
        <img src={content.image.url} alt={content.image.altText} />
      </div>
    </div>
  );
};

const FeaturesContent = ({ content }) => {
  return (
    <div className="features">
      {content.image && (
        <img src={content.image.url} alt={content.image.altText} />
      )}
      <div>
        <Heading text={content.heading} id={content.id} />
        {content.description && (
          <div
            className="paragraph"
            dangerouslySetInnerHTML={{ __html: content.description }}
          />
        )}
        <div>
          <ul>
            {content.features.map((feature, index) => (
              <li key={index}>
                <div dangerouslySetInnerHTML={{ __html: feature.paragraph }} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const ProsAndConsContent = ({ content }) => {
  return (
    <div className="pros-and-cons">
      <div>
        <Heading text={content.heading} id={content.id} />
        {content.description && (
          <div
            className="paragraph"
            dangerouslySetInnerHTML={{ __html: content.description }}
          />
        )}
        <div className="pros-and-cons-content">
          <div className="pros-and-cons-list">
            <h3 className="pros-or-cons-header">Pros</h3>
            {content.pros.map((pro) => (
              <div className="pro-or-con">
                <div dangerouslySetInnerHTML={{ __html: pro.paragraph }} />
              </div>
            ))}
          </div>
          <div className="pros-and-cons-list">
            <h3 className="pros-or-cons-header">Cons</h3>
            {content.cons.map((con) => (
              <div className="pro-or-con">
                <div dangerouslySetInnerHTML={{ __html: con.paragraph }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TipsContent = ({ content }) => {
  return (
    <div className="tips">
      <div>
        <Heading text={content.heading} id={content.id} />
        {content.description && (
          <div
            className="paragraph"
            dangerouslySetInnerHTML={{ __html: content.description }}
          />
        )}
        <div className="tips-content">
          {content.tips.map((tip) => (
            <div className="tip">
              <h3 className="tip-header">{tip.subheading}</h3>
              <div dangerouslySetInnerHTML={{ __html: tip.paragraph }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default function ReviewGuideContent({ content, contentType }) {
  const location = useLocation();

  return (
    <div className="review-guide-content">
      <div className="content">
        {content.map((item, index) => (
          <>
            <div
              key={index}
              className="content-section"
              style={{
                backgroundColor: item.content.backgroundColor || "transparent",
              }}
            >
              {item.type === "text" && (
                <TextContent id={index + 1} content={item.content} />
              )}
              {item.type === "faqs" && (
                <FaqsContent id={index + 1} content={item.content} />
              )}
              {item.type === "features" && (
                <FeaturesContent id={index + 1} content={item.content} />
              )}
              {item.type === "conclusion" && (
                <ConclusionContent
                  id={index + 1}
                  content={item.content}
                  contentType={contentType}
                />
              )}
              {item.type === "prosAndCons" && (
                <ProsAndConsContent id={index + 1} content={item.content} />
              )}
              {item.type === "tips" && (
                <TipsContent id={index + 1} content={item.content} />
              )}
            </div>

            <hr />
          </>
        ))}
        <FacebookComments location={location} />
      </div>
    </div>
  );
}
