import * as React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import "./Breadcrumbs.css";

export default function Breadcrumbs({ links }) {
  return (
    <div className="breadcrumbs">
      {links &&
        links.map((link, index) => {
          if (link.to) {
            return (
              <>
                <Link className="breadcrumb" to={link.to}>
                  <div>{link.title.toUpperCase()}</div>
                </Link>
                {index < links.length - 1 && (
                  <div className="breadcumb-separator">
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#000", fontSize: 15 }}
                    />
                  </div>
                )}
              </>
            );
          } else {
            return (
              <>
                <div className="breadcrumb" style={{ color: "#000" }}>
                  {link.title.toUpperCase()}
                </div>
                {index < links.length - 1 && (
                  <div className="breadcumb-separator">
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#000", fontSize: 15 }}
                    />
                  </div>
                )}
              </>
            );
          }
        })}
    </div>
  );
}
