import React, { Component } from "react";
import Helmet from "react-helmet";

const FacebookComments = ({ location }) => {
  return (
    <div style={{ maxWidth: "calc(100% - 40px)" }}>
      <Helmet>
        <script
          async
          defer
          crossorigin="anonymous"
          src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v18.0&appId=232563219828776"
          nonce="77f2nBbp"
        ></script>
      </Helmet>
      <div id="fb-root"></div>
      <div
        class="fb-comments"
        data-href={location.href}
        data-width=""
        data-numposts="5"
      ></div>
    </div>
  );
};

export default FacebookComments;
