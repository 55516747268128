import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Button from "../components/Button/Button";
import Footer from "../components/Footer/Footer";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import ReviewGuideContent from "../components/ReviewGuideContent/ReviewGuideContent";

import "./ToolPage.css";
import { GlobalStateContext } from "../context/GlobalContextProvider";

import slugGenerator from "../util/slugGenerator";

const ToolPage = ({ location, data }) => {
  const state = React.useContext(GlobalStateContext);

  const [activeItem, setActiveItem] = useState(null);

  const aiTool = data.aiTool;

  // Get the current path
  const currentPath = location.pathname;

  let links = [
    {
      title: "HOME",
      to: "/",
    },
  ];

  if (aiTool.aiToolCategories && aiTool.aiToolCategories.length > 0) {
    const aiToolCategory = aiTool.aiToolCategories[0];
    links.push({
      title: aiToolCategory.category.title,
      to: `/${slugGenerator(aiToolCategory.category.title)}/`,
    });
  }

  links.push({
    title: `${aiTool.title} Guide`.toUpperCase(),
  });

  let content = [];
  let headingContent = {};

  try {
    content = JSON.parse(aiTool.guide);
    headingContent = content[0];
    content.shift();
  } catch (e) {
    // console.log(e);
  }

  let headerCount = 1;
  const tableOfContents = [];

  if (content) {
    // Extract headings and build table of contents
    content.forEach((item) => {
      if (item.content && item.content.heading) {
        const id = `header-${headerCount++}`;
        tableOfContents.push({ text: item.content.heading, id });
        item.content.id = id;
      }
    });
  }

  React.useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      const sections = document.querySelectorAll("h2[id]");

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;

        if (rect.top <= 0 && rect.bottom > 0) {
          setActiveItem(section.id);
        }
      });
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTableOfContentsItemClick = (id) => {
    setActiveItem(id);
  };

  if (!content) {
    return <div />;
  }

  return (
    <Page>
      <TopBar showSearch={true} />
      {aiTool.title && (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            maxWidth: "1500px",
            margin: "0 auto",
          }}
        >
          <div>
            <Section
              style={{
                color: "#fff",
                lineHeight: "27px",
              }}
            >
              <Breadcrumbs links={links} />
              <SectionHeading>
                <div style={{ width: "100%" }}>
                  <span style={{ color: "#3578A0" }}>
                    {(headingContent &&
                      headingContent.content &&
                      headingContent.content.heading.toUpperCase()) ||
                      `HOW TO USE ${aiTool.title.toUpperCase()} Beginner's Guide`.toUpperCase()}
                  </span>
                </div>
              </SectionHeading>
              <div className="tool-profile" style={{ flexDirection: "column" }}>
                <div className="tool-profile-left">
                  <div className="screenshot-frame">
                    <img
                      src={aiTool.screenshot}
                      className="tool-screenshot"
                      alt={aiTool.title}
                    />
                  </div>
                  <div className="tool-buttons">
                    {aiTool.review && (
                      <Link to={`/${aiTool.slug}-review/`}>
                        <Button>READ OUR REVIEW</Button>
                      </Link>
                    )}
                    {aiTool.affiliateUrl && (
                      <a
                        href={aiTool.affiliateUrl}
                        target="_blank"
                        rel="nofollow"
                      >
                        <Button>VIEW PRODUCT</Button>
                      </a>
                    )}
                  </div>
                </div>

                <div className="tool-profile-right" style={{ width: "100%" }}>
                  <div
                    className="tool-description"
                    dangerouslySetInnerHTML={{
                      __html:
                        headingContent && headingContent.content
                          ? headingContent.content.description
                          : aiTool.summaryDescription,
                    }}
                  >
                    { }
                  </div>
                  {/* <div className="tool-page-tags">
                  {listOfTags.map((tag) => (
                    <Link to={`/${slugGenerator(tag)}/`}>
                      <div className="tag">{tag}</div>
                    </Link>
                  ))}
                </div> */}
                </div>
              </div>
            </Section>
            <Section backgroundColor="#fafafa">
              <div className="table-of-contents mobile">
                <div className="table-of-contents-wrapper">
                  <div className="table-of-contents-title">
                    <span>Table of Contents</span>
                  </div>
                  <ul>
                    {tableOfContents.map(({ text, id }) => (
                      <li key={id}>
                        <Link to={`#${id}/`}>{text}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <ReviewGuideContent content={content} contentType="guide" />
            </Section>
          </div>
          <div className="table-of-contents desktop">
            <div className="table-of-contents-wrapper">
              <div className="table-of-contents-title">
                <span>Table of Contents</span>
              </div>
              <ul>
                {tableOfContents.map(({ text, id }) => (
                  <li key={id}>
                    <Link
                      to={`#${id}`}
                      className={activeItem === id ? "active" : ""}
                      onClick={() => handleTableOfContentsItemClick(id)}
                    >
                      {text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </Page>
  );
};

export default ToolPage;

export const Head = ({ data }) => {
  const location = useLocation();

  const aiTool = data.aiTool;

  return (
    <SEO
      index
      follow
      title={
        aiTool.guideMetaTitle || `How To Use ${aiTool.title} | Beginner's Guide`
      }
      description={
        aiTool.guideMetaDescription ||
        `Read our beginners guide on how to use ${aiTool.title}. With our guide you can master ${aiTool.title} effortlessly with our useful tips. Check it out now!
      `
      }
      pathname={location.pathname}
      image={aiTool.screenshot}
    />
  );
};

export const query = graphql`
  query ToolPageQuery($slug: String!) {
    aiTool(slug: { eq: $slug }) {
      id
      slug
      title
      guide
      guideMetaTitle
      guideMetaDescription
      screenshot
      affiliateUrl
      aiToolCategories {
        categoryId
        category {
          title
        }
      }
    }
  }
`;
